import { useContext } from 'react'
import { useNavigate } from 'react-router'
import { Button } from '@mantine/core'

import classnames from 'classnames'
import { UserContext } from '../../context/UserContext'
import ProfileIcon from '../misc/ProfileIcon'

const BaseLayout = ({
  className,
  children,
  withHomePageBtn = true,
  withLogoutBtn = true,
  isAdminPage = false,
  isStudentPage = false,
}) => {
  const [user] = useContext(UserContext)

  const isAdmin = user?.role === 'admin'

  const navigate = useNavigate()
  const handleHomeNavigation = () => {
    navigate(isAdmin && !isAdminPage ? '/admin' : '/')
  }

  const getBtnText = () => {
    if (isAdminPage) return 'STUDENT VIEW'
    if (isStudentPage) return 'ADMIN VIEW'
    return 'HOME'
  }

  return (
    <div className={classnames('base-layout-outter', className)}>
      {(withHomePageBtn || withLogoutBtn) && (
        <div
          className="base-layout-inner base-layout-inner-top"
          style={withHomePageBtn ? { justifyContent: 'space-between' } : {}}
        >
          {isAdmin && withHomePageBtn && (
            <Button onClick={handleHomeNavigation} className="base-layout-home-btn">
              &#8592; {getBtnText()}
            </Button>
          )}
        </div>
      )}
      <div className="base-layout-inner">{children}</div>
      <ProfileIcon withLogoutBtn={withLogoutBtn} />
    </div>
  )
}

export default BaseLayout
