import { useContext, useState } from 'react'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { Button } from '@mantine/core'
import axios from '../../customAxios'

import { UserContext } from '../../context/UserContext'
import { handleRequestError } from '../../helpers'
import InterVueLogo from './InterVueLogo'
import ProfileImagePlaceholder from '../placeholders/ProfileImagePlaceholder'

const ProfileIcon = ({ withLogoutBtn = true }) => {
  const [isLoadingLogout, setIsLoadingLogout] = useState(false)
  const [user] = useContext(UserContext)
  const navigate = useNavigate()

  const handleLogout = async () => {
    try {
      setIsLoadingLogout(false)
      await axios.post('/users/logout')
      toast.success('Logged out successfully')
      navigate('/login')
    } catch (error) {
      handleRequestError(error)
      setIsLoadingLogout(true)
    }
  }

  return (
    <div className="base-layout-profile">
      <InterVueLogo />
      {withLogoutBtn && (
        <div className="base-layout-profile-inner">
          <span className="base-layout-profile-name">{user?.fullName}</span>
          <ProfileImagePlaceholder />
          <Button loading={isLoadingLogout} onClick={handleLogout} className="base-layout-logout">
            Logout
          </Button>
        </div>
      )}
    </div>
  )
}

export default ProfileIcon
