export const DARK_GREY = '#292929'
export const MEDIUM_GREY = '#474747'
export const WHITE_GREY = '#707070'
export const LIGHT_GREY = '#f7f7f7'
export const RED = '#f24e56'
export const BLUE = '#5bc0eb'
export const DARK_BLUE = '#1478A3'

export const BLUE_TRANSPARENT = 'rgba(91, 192, 235, 0.8)'
export const DARK_BLUE_TRANSPARENT = 'rgba(21, 66, 138, 0.8)'

export const singularWordMarkdown = '{{ word }}'
export const pluralWordMarkdown = '{{ wordPlural }}'
export const nameMarkdown = '{{ name }}'
export const wordPlaceholder = '_ _ _ _ _ _'
export const newQuestionName = '_new-question'
export const separators = [', ', ',', ' ', ';', '; ']
export const WORDS_TO_IGNORE = new Set(['and', 'or', 'a', 'an', 'to'])
