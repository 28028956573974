import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../../context/UserContext'
import BaseLayout from '../layouts/BaseLayout'
import axios from '../../customAxios'

const ProtectedRoute = ({ children, isAdmin }) => {
  const navigate = useNavigate()
  const [user, setUser] = useContext(UserContext)
  const isAuth = Boolean(user?.email)
  const isAdminUser = user?.role === 'admin'
  const checkAuth = async () => {
    try {
      const response = await axios.get('/users/me')
      const loggedInUser = response.data.user
      setUser({ ...loggedInUser, loading: false })
      if (isAdmin && loggedInUser?.role !== 'admin') navigate('/login')
    } catch (error) {
      navigate('/login')
    }
  }
  useEffect(() => {
    if (!isAuth) checkAuth()
  }, [])
  if ((!isAdmin && isAuth) || (isAdmin && isAdminUser)) return children

  return <BaseLayout />
}

export default ProtectedRoute
