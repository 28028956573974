import { lazy, Suspense, useMemo, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import PrivateRoute from './components/authentication/PrivateRoute'
import { UserContext } from './context/UserContext'

const AdminDashboard = lazy(() => import('./pages/admin/AdminDashboard'))
const AdminQuestions = lazy(() => import('./pages/admin/AdminQuestions'))
const AdminWordsList = lazy(() => import('./pages/admin/AdminWordsList'))
const Home = lazy(() => import('./pages/Home'))
const Login = lazy(() => import('./pages/Login'))

function App() {
  const [user, setUser] = useState()
  const userProviderValue = useMemo(() => [user, setUser], [user, setUser])

  return (
    <UserContext.Provider value={userProviderValue}>
      <BrowserRouter>
        <Suspense>
          <Routes>
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <Home />
                </PrivateRoute>
              }
            />
            <Route path="/login" element={<Login />} />
            <Route
              path="/admin"
              element={
                <PrivateRoute isAdmin>
                  <AdminDashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/words/:id" // all words by user Id
              element={
                <PrivateRoute isAdmin>
                  <AdminWordsList />
                </PrivateRoute>
              }
            />
            <Route
              path="/words"
              element={
                <PrivateRoute isAdmin>
                  <AdminWordsList />
                </PrivateRoute>
              }
            />
            <Route
              path="/questions"
              element={
                <PrivateRoute isAdmin>
                  <AdminQuestions />
                </PrivateRoute>
              }
            />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </UserContext.Provider>
  )
}

export default App
