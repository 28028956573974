const ProfileImagePlaceholder = () => {
  return (
    <img
      className="base-layout-profile-image"
      src="/img/profile-placeholder.png"
      alt="default-profile-image"
    />
  )
}

export default ProfileImagePlaceholder
