import { Fragment } from 'react'
import { toast } from 'react-toastify'
import {
  nameMarkdown,
  pluralWordMarkdown,
  singularWordMarkdown,
  wordPlaceholder,
  WORDS_TO_IGNORE,
} from './constants'

/**
 * Gets the names of input fields
 * @param {Array} questions
 * @returns {Array | string} // array for text, string for radio
 */
export const getInputFields = (questions) => {
  return questions.reduce(
    (prev, question) => ({
      ...prev,
      [question.name]: new Array(question.answersCount || 1).fill(''),
    }),
    {}
  )
}

export const getPropNames = (question, form) => {
  const count = question.answersCount || 1
  const props = []
  for (let index = 0; index < count; index++) {
    const propName = `${question.name}.${index}`
    props.push(form.getInputProps(propName))
  }
  return props
}

export const handleRequestError = (error) => {
  let errorMessage
  if (typeof error.response?.data?.message === 'string')
    errorMessage = error.response?.data?.message
  else if (typeof error.message === 'string') errorMessage = error.message
  else if (typeof error === 'string') errorMessage = error
  else errorMessage = 'Something went wrong'
  toast.error(errorMessage)
}

export const validateEmail = (email) => {
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return email.match(pattern)
}

export const textToHtml = (text, name) => {
  const content = text
    .replace(singularWordMarkdown, wordPlaceholder)
    .replace(pluralWordMarkdown, wordPlaceholder)
    .replace(nameMarkdown, `<span class='highlighted'>${name}</span>`)
  return `<p class='subtitle'>${content}</p>`
}

export const getTextContent = (question) => {
  if (!question?.htmlContent) return ''
  const div = document.createElement('div')
  div.innerHTML = question.htmlContent
  const p = div.getElementsByTagName('p')[0]
  const textContent =
    p?.innerText.replace(
      wordPlaceholder,
      question.isWordPlural ? pluralWordMarkdown : singularWordMarkdown
    ) || ''
  const highlightedWord = p.getElementsByTagName('span')[0]?.innerText || ''
  return highlightedWord ? textContent.replace(highlightedWord, nameMarkdown).trim() : textContent
}

export const getRandomString = () => (Math.random() + 1).toString(36).substring(6)

export const getFirstName = (user) => user.fullName.split(' ')[0]

export const capitalize = (string) => {
  if (!string) return ''
  return string[0].toUpperCase() + string.slice(1).toLowerCase()
}

export const getWordMessage = (word, isFirstMessage = false) => {
  const message = isFirstMessage
    ? `Welcome! The first word is "${word}"`
    : `Your new word is "${word}"`
  return {
    isFromActiveUser: false,
    content: <p className="subtitle">{message}</p>,
    id: word + getRandomString(),
    word,
  }
}

export const getMessageFromQuestion = (question, word, hasPrevSameQuestion, remainingCount) => ({
  isFromActiveUser: false,
  htmlContent: question?.htmlContent,
  word,
  questionName: question.name,
  ...(hasPrevSameQuestion && {
    content: <p>You have {remainingCount} answer remaining for:</p>,
  }),
  ...(question.type === 'radio' && {
    contentAfter: (
      <p>
        Options:{' '}
        {question.radioValues.map((option) => (
          <span key={option}>{option} </span>
        ))}
      </p>
    ),
  }),
  id: question?._id + getRandomString(),
})

export const getMessageFormValues = (values, word) => {
  const htmlContent = (
    <p className="subtitle">
      <span>Your answers are:</span>
      <br />
      {Object.entries(values).map(([name, values]) => {
        return (
          <Fragment key={name}>
            <span>
              {capitalize(name)}: {values.join(', ')}
            </span>
            <br />
          </Fragment>
        )
      })}
    </p>
  )
  return {
    isFromActiveUser: false,
    content: htmlContent,
    word,
    id: getRandomString(),
  }
}

export const getWordsFromMessage = (message) =>
  message.split(' ').filter((word) => word && !WORDS_TO_IGNORE.has(word.toLowerCase()))
