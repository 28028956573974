import React from 'react'
import ReactDOM from 'react-dom/client'
import { Global, MantineProvider } from '@mantine/core'
import { ToastContainer } from 'react-toastify'

import { DARK_GREY } from './constants'
import App from './App'

import 'react-toastify/dist/ReactToastify.css'
import './styles/main.scss'

const root = ReactDOM.createRoot(document.getElementById('root'))

function MyGlobalStyles() {
  return (
    <Global
      styles={(theme) => ({
        body: { backgroundColor: DARK_GREY },
      })}
    />
  )
}

root.render(
  <React.StrictMode>
    <MantineProvider
      theme={{
        fontFamily:
          "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;",
        components: {
          Modal: {
            classNames: {
              modal: 'modal',
              close: 'modal-close-btn',
            },
          },
          Checkbox: {
            classNames: {
              label: 'text-input-label',
              input: 'checkbox-input',
            },
          },
          TextInput: {
            classNames: {
              input: 'text-input',
              error: 'text-input-error',
              label: 'text-input-label',
            },
          },
          NumberInput: {
            classNames: {
              input: 'text-input',
              error: 'text-input-error',
              label: 'text-input-label',
              rightSection: 'number-input-rightSection',
            },
          },
          Select: {
            classNames: {
              input: 'text-input',
              error: 'text-input-error',
              label: 'text-input-label',
              dropdown: 'select-input-dropdown',
              item: 'select-input-item',
            },
          },
          MultiSelect: {
            classNames: {
              input: 'text-input text-input-multiselect',
              error: 'text-input-error',
              label: 'text-input-label text-label-multiselect',
              dropdown: 'select-input-dropdown',
              item: 'select-input-item',
              value: 'multiselect-value',
            },
          },
          FileInput: {
            classNames: {
              disabled: 'file-input-disabled',
              input: 'text-input file-input',
              error: 'text-input-error',
              label: 'text-input-label',
            },
          },
          Radio: { classNames: { label: 'radio-label', radio: 'radio' } },
          Button: {
            classNames: { root: 'button-root', label: 'button-label' },
          },
          Popover: {
            classNames: { dropdown: 'popover-dropdown' },
          },
        },
      }}
      withGlobalStyles
      withNormalizeCSS
    >
      <MyGlobalStyles />
      <App />
      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={false}
        theme="dark"
      />
    </MantineProvider>
  </React.StrictMode>
)
